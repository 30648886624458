import {SpotlightWaitlistConstants} from '../../../adapters/helpers/Constants';

const customModalInterestitalStyles = (isMedium) => {
    let modalConfig = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        position: 'absolute',
        background: `linear-gradient(50.05deg, #181D33 0%, #2C487B 69.26%, #57748B 100%)`,
        border: '8px solid #fff',
        borderRadius: '0',
        fontSize: '1.1rem',
        lineHeight: '2.1rem',
        padding: '0',
        transform: isMedium ? 'translate(-50%, -47%)' : 'translate(-50%, -50%)',
        width: isMedium ? '99%' :'100%',
        minWidth: isMedium ? '99%' :'75%',
        maxWidth: isMedium ? '100%' : '900px',
        height: isMedium ? '80vh' :'auto',
        maxHeight: isMedium ? '80vh' : SpotlightWaitlistConstants.interestitialModalMaxHeight,
        overflowX: 'hidden'
    }
    }
    return modalConfig;
};

const customStylesInterestitialLegalModal = (isMedium, backgroundColor) => {
    let modalConfig = {
        overlay: {
            backgroundColor: 'none'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            marginRight: '-50%',
            position: 'absolute',
            border: 'none',
            bottom: isMedium ? '5%' : 'auto',
            maxWidth: isMedium ? '80%' :'100%',
            width: isMedium ? '80%' : '70%',
            maxHeight: isMedium ? '300px' : '200px',
            height: isMedium ? '280px' : '200px',
            boxShadow: 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background: `linear-gradient(to right bottom, ${backgroundColor}, ${backgroundColor})`,
            transform: isMedium ? 'translate(-50%, -50%)' : 'translate(-50%, 10%)',
            zIndex: SpotlightWaitlistConstants.interestitialSecondModalZIndex
        }
    }

    return modalConfig;
};

export {
    customModalInterestitalStyles,
    customStylesInterestitialLegalModal
}
